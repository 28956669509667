export const pg_Services = [
    {   id: 0,
        name_en: "UX, UI, Graphic",
        name_ru: "UX, UI, Графика",
        de1_ru: "Архитектура, удобство и внешний вид проекта",
        de1_en: "The architecture, convenience and appearance",
        de2_ru: "напрямую зависят от этих навыков.",
        de2_en: "of project directly depend on these skills.",
        cats: [
            {
                cat_en: "Architecture",
                cat_ru: "Проектирование",
                works: [
                    {
                        work_en: "Create application for Android",
                        work_ru: "Создать приложение для Android",
                        txt_en: "The application will be written in Java or Kotlin strictly for Android devices. This will allow you to use all the features of this OS, increase your ranking in Google Play and improve performance compared to cross-platform solutions.",
                        txt_ru: "Приложение будет написано на Java или Kotlin строго для устройств Android. Это позволит вам использовать все возможности данной ОС, повысит рейтинг в Google Play и улучшит производительность в сравнении с кроссплатформенными решениями.",
                        calc: true,
                    },
                    {
                        work_en: "Create application for iOS",
                        work_ru: "Создать приложение для iOS",
                        txt_en: "The application will be written in Swift or Objective-C strictly for iOS devices. This will allow you to use all the features of this OS, increase your Apple Store rating and improve performance compared to cross-platform solutions.",
                        txt_ru: "Приложение будет написано на Swift или на Objective-C строго для устройств iOS. Это позволит вам использовать все возможности данной ОС, повысить рейтинг Apple Store и улучшит производительность в сравнении с кроссплатформенными решениями.",
                        calc: true,
                    },
                    {
                        work_en: "Create Cross-platform application",
                        work_ru: "Создать Кроссплатформенное приложение",
                        txt_en: "Applications written in C# or created in Cordova can be published on any platform: iOS, Android, Linux, Windows. It's faster and cheaper than creating + maintaining several applications for each OS. But be aware that this approach entails a decrease in the quality of the product.",
                        txt_ru: "Написанные на языке C# или созданные в Cordova - приложения можно будет публиковать на любой платформе: iOs, Android, Linux, Windows. Это быстрее и дешевле чем создавать + поддерживать несколько приложений по каждую ОС. Но знайте, что такой подход влечет снижение качества продукта.",
                        calc: true,
                    },
                    {
                        work_en: "Create Cross-platform in online builder",
                        work_ru: "Создать Кроссплатформенное в онлайн конструкторе",
                        txt_en: "Creating an application in an online designer is the cheapest and fastest way. But this option is fraught with many limitations and disadvantages. Suitable for simple applications with minimal functionality, such as: Catalogs, Ratings, Trainings and other information applications.",
                        txt_ru: "Создание приложения в онлайн конструкторе - самый дешевый и быстрый способ. Но этот вариант таит множество ограничений и недостатков. Подходит для простых приложений с минимальным функционалом, таких как: Каталоги, Рейтинги, Тренинги и другие информационные приложения.",
                        calc: true,
                    },
                    {
                        work_en: "Publishing in stores",
                        work_ru: "Публикация в маркетплейсах",
                        txt_en: "It is not enough to create an application, you also need to publish it on the store(s). We will take into account all the requirements, features of each of the stores, and carry out all bureaucratic procedures. We will create and fill the application account(s) with relevant content.",
                        txt_ru: "Не достаточно создать приложение, его нужно еще опубликовать на маркетплейсе(ах). Учтем все требования, особенности каждого из маркетплейсов, и проведем все бюрократические процедуры. Создадим и наполним аккаунт(ы)приложения релевантным контент.",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Usability",
                cat_ru: "Юзабилити",
                works: [

                ],
            },
            {
                cat_en: "Visual design",
                cat_ru: "Визуальный стиль",
                works: [

                ],
            },
            {
                cat_en: "Testing",
                cat_ru: "Тестирование",
                works: [

                ],
            },
            {
                cat_en: "Content",
                cat_ru: "Контент",
                works: [

                ],
            },
        ],
    },
    {   id: 1,
        name_en: "Frontend",
        name_ru: "Фронтенд",
        de1_ru: "Механика и скорость работы клиентского интерфейса,",
        de1_en: "Mechanics and speed of a client interface,",
        de2_ru: "а также рейтинг проекта в поисковых системах.",
        de2_en: "as well as project’s rating in search engines.",
        cats: [
            {
                cat_en: "Layout by classic",
                cat_ru: "Вёрстка классическая",
                works: [
                    {
                        work_en: "Completely unique website, written from scratch",
                        work_ru: "Полностью уникальный сайт, написанный с нуля",
                        txt_en: "When you need to create a unique or large website, ready-made admin panels either lack functionality or the necessary security, and there are no ready-made design templates at all. In this case, the site is designed and created from A to Z individually for your idea.",
                        txt_ru: "Когда нужно создать уникальный или большой сайт - готовым админ панелям либо не хватает функционалу, либо нужной безопасности, а готовых дизайн-шаблонов вовсе не существует. В таком случае сайт проектируется и создаётся от А до Я индивидуально под вашу идею.",
                        calc: true,
                    },
                    {
                        work_en: "With unique design but with ready-made admin panel",
                        work_ru: "С уникальным дизайном но готовой админ панелью",
                        txt_en: "Often you need to create a website to perform popular types of tasks, such as: online stores, landing pages, portfolios, etc. At the same time, the appearance and behavior of the elements should be unique (for example, in a corporate style or as in some kind of example).",
                        txt_ru: "Часто нужно создать сайт для выполнения популярных видов задач, таких как: Интернет магазины, Лендинги, Портфолио и т.п. При этом чтобы внешний вид и поведение элементов были уникальным (например в фирменном стиле или как в каком-то референсе).",
                        calc: true,
                    },
                    {
                        work_en: "Based on a ready-made website template",
                        work_ru: "На основе готового шаблона сайтов",
                        txt_en: "When you need to quickly create a website for a popular task, such as: Online stores, Landing pages, Portfolios, etc. We can use ready-made, template solutions. You should take into account that such a solution may have slow speed, poor SEO, and may also be difficult to expand functionality.",
                        txt_ru: "Когда нужно быстро создать сайт для популярной задачи, такой как: Интернет магазины, Лендинги, Портфолио и т.п. Можно использовать готовые, шаблонные решения. Вам следует учитывать что у такого решения может быть медленная скорость, плохое SEO, а также вероятны сложности с расширением функционала.",
                        calc: true,
                    },
                    {
                        work_en: "In online website builder",
                        work_ru: "В онлайн конструкторе сайтов",
                        txt_en: "This is the fastest way to create a website. You will be able to edit and expand the functionality of the site without the participation of programmers. But this option is fraught with many disadvantages and limitations. We do not recommend it if you want promotion in search engines. Suitable for simple, informational sites with minimal functionality, such as: Business card, Landing page, Blog...",
                        txt_ru: "Это самый быстрый способ создания сайта. Вы сможете редактировать и расширять функционал сайта без участия программистов. Но этот вариант таит множество недостатков и ограничений. Не рекомендуем его если вы хотите продвижения в поисковиках. Подходит для простых, информационных сайтов с минимальным функционалом, таких как: Визитка, Лендинг, Блог...",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Layout for web apps",
                cat_ru: "Вёрстка приложений",
                works: [
                    {
                        work_en: "Moving to another server",
                        work_ru: "Переезд на другой сервер",
                        txt_en: "We will transfer / copy your website to another hosting (server) and, of course, solve all problems that arise along the way. Also, if necessary, we will help with choosing a server for your project.",
                        txt_ru: "Мы перенесём / копируем ваш сайт на другой хостинг (сервер) и конечно же решим все попутно возникающие задачи. Также в случае необходимости поможем с выбором сервера для вашего проекта.",
                        calc: true,
                    },
                    {
                        work_en: "Working with domains",
                        work_ru: "Работы с доменами",
                        txt_en: "Do you need to change your website domain? Sorting out confusion about certificates? Link the work of several domains / subdomains / hostings? Set up redirects? We will help and of course give recommendations if necessary.",
                        txt_ru: "Вам нужно сменить домен сайта? Разобраться с путаницей в сертификатах? Связать работу нескольких доменов / поддоменов / хостингов? Настроить переадресации? Поможем и конечно дадим рекомендации если понадобится.",
                        calc: true,
                    },
                    {
                        work_en: "Switching to versioning (GIT)",
                        work_ru: "Переход на работу с версиями (GIT)",
                        txt_en: "Do you have several developers working on your project at once? Then the GIT version control system will help you a lot. You will see when, what and by whom changes were made. You can roll back individual changes. The project will be protected from accidental or intentional damage by developers.",
                        txt_ru: "Над вашем проектом работает сразу несколько разработчиков? Тогда вам очень поможет система контроля версий GIT. Вы будите видеть когда, какие и кем были сделаны изменения. Сможете откатывать отдельные изменения. Проект будет защищён от случайной или намеренной порчи со стороны разработчиков.",
                        calc: true,
                    },
                    {
                        work_en: "Mail servers setup",
                        work_ru: "Настройка почтовых серверов",
                        txt_en: "Need to install or configure a mail server? Implement automatic replies and newsletters from the site? Make it possible to log in to a website using popular email services and social networks? We do all this with our eyes closed :)",
                        txt_ru: "Нужно установить или настроить работу почтового сервера? Реализовать автоматические ответы и рассылки с сайта? Сделать возможным авторизацию на сайте при помощи популярных почтовых сервисов и соц сетей? Всё это мы делаем с закрытыми глазами :)",
                        calc: true,
                    },
                    {
                        work_en: "Setting up any API",
                        work_ru: "Настройка любого API",
                        txt_en: "We will connect the operation of your website with any services that have an API. If you need third-party services to be able to interact with your site themselves, then we will create your own API for any task.",
                        txt_ru: "Свяжем работу вашего сайта с любыми сервисами имеющими API. Если же вам нужно чтобы сторонние сервисы могли сами взаимодействовать с ваши сайтом, то мы создадим вам собственный API под любые задачи.",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Interactive and Coding",
                cat_ru: "Интерактив и Кодинг",
                works: [
                    {
                        work_en: "Completely unique website, written from scratch",
                        work_ru: "Полностью уникальный сайт, написанный с нуля",
                        txt_en: "When you need to create a unique or large website, ready-made admin panels either lack functionality or the necessary security, and there are no ready-made design templates at all. In this case, the site is designed and created from A to Z individually for your idea.",
                        txt_ru: "Когда нужно создать уникальный или большой сайт - готовым админ панелям либо не хватает функционалу, либо нужной безопасности, а готовых дизайн-шаблонов вовсе не существует. В таком случае сайт проектируется и создаётся от А до Я индивидуально под вашу идею.",
                        calc: true,
                    },
                    {
                        work_en: "With unique design but with ready-made admin panel",
                        work_ru: "С уникальным дизайном но готовой админ панелью",
                        txt_en: "Often you need to create a website to perform popular types of tasks, such as: online stores, landing pages, portfolios, etc. At the same time, the appearance and behavior of the elements should be unique (for example, in a corporate style or as in some kind of example).",
                        txt_ru: "Часто нужно создать сайт для выполнения популярных видов задач, таких как: Интернет магазины, Лендинги, Портфолио и т.п. При этом чтобы внешний вид и поведение элементов были уникальным (например в фирменном стиле или как в каком-то референсе).",
                        calc: true,
                    },
                    {
                        work_en: "Based on a ready-made website template",
                        work_ru: "На основе готового шаблона сайтов",
                        txt_en: "When you need to quickly create a website for a popular task, such as: Online stores, Landing pages, Portfolios, etc. We can use ready-made, template solutions. You should take into account that such a solution may have slow speed, poor SEO, and may also be difficult to expand functionality.",
                        txt_ru: "Когда нужно быстро создать сайт для популярной задачи, такой как: Интернет магазины, Лендинги, Портфолио и т.п. Можно использовать готовые, шаблонные решения. Вам следует учитывать что у такого решения может быть медленная скорость, плохое SEO, а также вероятны сложности с расширением функционала.",
                        calc: true,
                    },
                    {
                        work_en: "In online website builder",
                        work_ru: "В онлайн конструкторе сайтов",
                        txt_en: "This is the fastest way to create a website. You will be able to edit and expand the functionality of the site without the participation of programmers. But this option is fraught with many disadvantages and limitations. We do not recommend it if you want promotion in search engines. Suitable for simple, informational sites with minimal functionality, such as: Business card, Landing page, Blog...",
                        txt_ru: "Это самый быстрый способ создания сайта. Вы сможете редактировать и расширять функционал сайта без участия программистов. Но этот вариант таит множество недостатков и ограничений. Не рекомендуем его если вы хотите продвижения в поисковиках. Подходит для простых, информационных сайтов с минимальным функционалом, таких как: Визитка, Лендинг, Блог...",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Integrations",
                cat_ru: "Интеграции",
                works: [
                    {
                        work_en: "Completely unique website, written from scratch",
                        work_ru: "Полностью уникальный сайт, написанный с нуля",
                        txt_en: "When you need to create a unique or large website, ready-made admin panels either lack functionality or the necessary security, and there are no ready-made design templates at all. In this case, the site is designed and created from A to Z individually for your idea.",
                        txt_ru: "Когда нужно создать уникальный или большой сайт - готовым админ панелям либо не хватает функционалу, либо нужной безопасности, а готовых дизайн-шаблонов вовсе не существует. В таком случае сайт проектируется и создаётся от А до Я индивидуально под вашу идею.",
                        calc: true,
                    },
                    {
                        work_en: "With unique design but with ready-made admin panel",
                        work_ru: "С уникальным дизайном но готовой админ панелью",
                        txt_en: "Often you need to create a website to perform popular types of tasks, such as: online stores, landing pages, portfolios, etc. At the same time, the appearance and behavior of the elements should be unique (for example, in a corporate style or as in some kind of example).",
                        txt_ru: "Часто нужно создать сайт для выполнения популярных видов задач, таких как: Интернет магазины, Лендинги, Портфолио и т.п. При этом чтобы внешний вид и поведение элементов были уникальным (например в фирменном стиле или как в каком-то референсе).",
                        calc: true,
                    },
                    {
                        work_en: "Based on a ready-made website template",
                        work_ru: "На основе готового шаблона сайтов",
                        txt_en: "When you need to quickly create a website for a popular task, such as: Online stores, Landing pages, Portfolios, etc. We can use ready-made, template solutions. You should take into account that such a solution may have slow speed, poor SEO, and may also be difficult to expand functionality.",
                        txt_ru: "Когда нужно быстро создать сайт для популярной задачи, такой как: Интернет магазины, Лендинги, Портфолио и т.п. Можно использовать готовые, шаблонные решения. Вам следует учитывать что у такого решения может быть медленная скорость, плохое SEO, а также вероятны сложности с расширением функционала.",
                        calc: true,
                    },
                    {
                        work_en: "In online website builder",
                        work_ru: "В онлайн конструкторе сайтов",
                        txt_en: "This is the fastest way to create a website. You will be able to edit and expand the functionality of the site without the participation of programmers. But this option is fraught with many disadvantages and limitations. We do not recommend it if you want promotion in search engines. Suitable for simple, informational sites with minimal functionality, such as: Business card, Landing page, Blog...",
                        txt_ru: "Это самый быстрый способ создания сайта. Вы сможете редактировать и расширять функционал сайта без участия программистов. Но этот вариант таит множество недостатков и ограничений. Не рекомендуем его если вы хотите продвижения в поисковиках. Подходит для простых, информационных сайтов с минимальным функционалом, таких как: Визитка, Лендинг, Блог...",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Testing",
                cat_ru: "Тестирование",
                works: [

                ],
            },
            {
                cat_en: "SEO",
                cat_ru: "SEO",
                works: [
                    {
                        work_en: "Completely unique website, written from scratch",
                        work_ru: "Полностью уникальный сайт, написанный с нуля",
                        txt_en: "When you need to create a unique or large website, ready-made admin panels either lack functionality or the necessary security, and there are no ready-made design templates at all. In this case, the site is designed and created from A to Z individually for your idea.",
                        txt_ru: "Когда нужно создать уникальный или большой сайт - готовым админ панелям либо не хватает функционалу, либо нужной безопасности, а готовых дизайн-шаблонов вовсе не существует. В таком случае сайт проектируется и создаётся от А до Я индивидуально под вашу идею.",
                        calc: true,
                    },
                    {
                        work_en: "With unique design but with ready-made admin panel",
                        work_ru: "С уникальным дизайном но готовой админ панелью",
                        txt_en: "Often you need to create a website to perform popular types of tasks, such as: online stores, landing pages, portfolios, etc. At the same time, the appearance and behavior of the elements should be unique (for example, in a corporate style or as in some kind of example).",
                        txt_ru: "Часто нужно создать сайт для выполнения популярных видов задач, таких как: Интернет магазины, Лендинги, Портфолио и т.п. При этом чтобы внешний вид и поведение элементов были уникальным (например в фирменном стиле или как в каком-то референсе).",
                        calc: true,
                    },
                    {
                        work_en: "Based on a ready-made website template",
                        work_ru: "На основе готового шаблона сайтов",
                        txt_en: "When you need to quickly create a website for a popular task, such as: Online stores, Landing pages, Portfolios, etc. We can use ready-made, template solutions. You should take into account that such a solution may have slow speed, poor SEO, and may also be difficult to expand functionality.",
                        txt_ru: "Когда нужно быстро создать сайт для популярной задачи, такой как: Интернет магазины, Лендинги, Портфолио и т.п. Можно использовать готовые, шаблонные решения. Вам следует учитывать что у такого решения может быть медленная скорость, плохое SEO, а также вероятны сложности с расширением функционала.",
                        calc: true,
                    },
                    {
                        work_en: "In online website builder",
                        work_ru: "В онлайн конструкторе сайтов",
                        txt_en: "This is the fastest way to create a website. You will be able to edit and expand the functionality of the site without the participation of programmers. But this option is fraught with many disadvantages and limitations. We do not recommend it if you want promotion in search engines. Suitable for simple, informational sites with minimal functionality, such as: Business card, Landing page, Blog...",
                        txt_ru: "Это самый быстрый способ создания сайта. Вы сможете редактировать и расширять функционал сайта без участия программистов. Но этот вариант таит множество недостатков и ограничений. Не рекомендуем его если вы хотите продвижения в поисковиках. Подходит для простых, информационных сайтов с минимальным функционалом, таких как: Визитка, Лендинг, Блог...",
                        calc: true,
                    },
                ],
            },
        ],
    },
    {   id: 2,
        name_en: "Backend",
        name_ru: "Бэкенд",
        de1_ru: "Хранение и обработка данных, админки, безопасность,",
        de1_en: "Data storage and processing, admin areas, security,",
        de2_ru: "обработка API запросов, нагрузки и стабильность...",
        de2_en: "processing API requests, loads and stability...",
        cats: [
            {
                cat_en: "Server setup",
                cat_ru: "Настройка сервера",
                works: [
                    {
                        work_en: "Create program for Windows",
                        work_ru: "Создать программу под Windows",
                        txt_en: "The program will be written in C# or Microsoft Visual Basic. This will allow you to use all the features of Windows OS as efficiently as possible.",
                        txt_ru: "Программа будет написана на C# или на Microsoft Visual Basic. Это позволит использовать максимально эффективно все возможности ОС Windows.",
                        calc: true,
                    },
                    {
                        work_en: "Create program for Mac OS",
                        work_ru: "Создать программу под Mac OS",
                        txt_en: "The program will be written in Swift or Objective-C. This will allow you to make the most of all the features of the Mac OS.",
                        txt_ru: "Программа будет написана на Swift или на Objective-C. Это позволит использовать максимально эффективно все возможности ОС Mac.",
                        calc: true,
                    },
                    {
                        work_en: "Create program for SmartTV / Android",
                        work_ru: "Создать программу под SmartTV / Android",
                        txt_en: "The program will be written using Smartbox libraries. This will allow the program to be cross-platform and work well on displays of any manufacturer.",
                        txt_ru: "Программа будет написана с использованием библиотек Smartbox. Это позволит программе быть кроссплатформенной и хорошо работать на дисплеях любой фирмы производителя.",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Admin panels",
                cat_ru: "Админ панели",
                works: [
                    {
                        work_en: "Completely unique website, written from scratch",
                        work_ru: "Полностью уникальный сайт, написанный с нуля",
                        txt_en: "When you need to create a unique or large website, ready-made admin panels either lack functionality or the necessary security, and there are no ready-made design templates at all. In this case, the site is designed and created from A to Z individually for your idea.",
                        txt_ru: "Когда нужно создать уникальный или большой сайт - готовым админ панелям либо не хватает функционалу, либо нужной безопасности, а готовых дизайн-шаблонов вовсе не существует. В таком случае сайт проектируется и создаётся от А до Я индивидуально под вашу идею.",
                        calc: true,
                    },
                    {
                        work_en: "With unique design but with ready-made admin panel",
                        work_ru: "С уникальным дизайном но готовой админ панелью",
                        txt_en: "Often you need to create a website to perform popular types of tasks, such as: online stores, landing pages, portfolios, etc. At the same time, the appearance and behavior of the elements should be unique (for example, in a corporate style or as in some kind of example).",
                        txt_ru: "Часто нужно создать сайт для выполнения популярных видов задач, таких как: Интернет магазины, Лендинги, Портфолио и т.п. При этом чтобы внешний вид и поведение элементов были уникальным (например в фирменном стиле или как в каком-то референсе).",
                        calc: true,
                    },
                    {
                        work_en: "Based on a ready-made website template",
                        work_ru: "На основе готового шаблона сайтов",
                        txt_en: "When you need to quickly create a website for a popular task, such as: Online stores, Landing pages, Portfolios, etc. We can use ready-made, template solutions. You should take into account that such a solution may have slow speed, poor SEO, and may also be difficult to expand functionality.",
                        txt_ru: "Когда нужно быстро создать сайт для популярной задачи, такой как: Интернет магазины, Лендинги, Портфолио и т.п. Можно использовать готовые, шаблонные решения. Вам следует учитывать что у такого решения может быть медленная скорость, плохое SEO, а также вероятны сложности с расширением функционала.",
                        calc: true,
                    },
                    {
                        work_en: "In online website builder",
                        work_ru: "В онлайн конструкторе сайтов",
                        txt_en: "This is the fastest way to create a website. You will be able to edit and expand the functionality of the site without the participation of programmers. But this option is fraught with many disadvantages and limitations. We do not recommend it if you want promotion in search engines. Suitable for simple, informational sites with minimal functionality, such as: Business card, Landing page, Blog...",
                        txt_ru: "Это самый быстрый способ создания сайта. Вы сможете редактировать и расширять функционал сайта без участия программистов. Но этот вариант таит множество недостатков и ограничений. Не рекомендуем его если вы хотите продвижения в поисковиках. Подходит для простых, информационных сайтов с минимальным функционалом, таких как: Визитка, Лендинг, Блог...",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Database",
                cat_ru: "Базы данных",
                works: [
                    {
                        work_en: "Completely unique website, written from scratch",
                        work_ru: "Полностью уникальный сайт, написанный с нуля",
                        txt_en: "When you need to create a unique or large website, ready-made admin panels either lack functionality or the necessary security, and there are no ready-made design templates at all. In this case, the site is designed and created from A to Z individually for your idea.",
                        txt_ru: "Когда нужно создать уникальный или большой сайт - готовым админ панелям либо не хватает функционалу, либо нужной безопасности, а готовых дизайн-шаблонов вовсе не существует. В таком случае сайт проектируется и создаётся от А до Я индивидуально под вашу идею.",
                        calc: true,
                    },
                    {
                        work_en: "With unique design but with ready-made admin panel",
                        work_ru: "С уникальным дизайном но готовой админ панелью",
                        txt_en: "Often you need to create a website to perform popular types of tasks, such as: online stores, landing pages, portfolios, etc. At the same time, the appearance and behavior of the elements should be unique (for example, in a corporate style or as in some kind of example).",
                        txt_ru: "Часто нужно создать сайт для выполнения популярных видов задач, таких как: Интернет магазины, Лендинги, Портфолио и т.п. При этом чтобы внешний вид и поведение элементов были уникальным (например в фирменном стиле или как в каком-то референсе).",
                        calc: true,
                    },
                    {
                        work_en: "Based on a ready-made website template",
                        work_ru: "На основе готового шаблона сайтов",
                        txt_en: "When you need to quickly create a website for a popular task, such as: Online stores, Landing pages, Portfolios, etc. We can use ready-made, template solutions. You should take into account that such a solution may have slow speed, poor SEO, and may also be difficult to expand functionality.",
                        txt_ru: "Когда нужно быстро создать сайт для популярной задачи, такой как: Интернет магазины, Лендинги, Портфолио и т.п. Можно использовать готовые, шаблонные решения. Вам следует учитывать что у такого решения может быть медленная скорость, плохое SEO, а также вероятны сложности с расширением функционала.",
                        calc: true,
                    },
                    {
                        work_en: "In online website builder",
                        work_ru: "В онлайн конструкторе сайтов",
                        txt_en: "This is the fastest way to create a website. You will be able to edit and expand the functionality of the site without the participation of programmers. But this option is fraught with many disadvantages and limitations. We do not recommend it if you want promotion in search engines. Suitable for simple, informational sites with minimal functionality, such as: Business card, Landing page, Blog...",
                        txt_ru: "Это самый быстрый способ создания сайта. Вы сможете редактировать и расширять функционал сайта без участия программистов. Но этот вариант таит множество недостатков и ограничений. Не рекомендуем его если вы хотите продвижения в поисковиках. Подходит для простых, информационных сайтов с минимальным функционалом, таких как: Визитка, Лендинг, Блог...",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Coding",
                cat_ru: "Кодинг",
                works: [
                    {
                        work_en: "Completely unique website, written from scratch",
                        work_ru: "Полностью уникальный сайт, написанный с нуля",
                        txt_en: "When you need to create a unique or large website, ready-made admin panels either lack functionality or the necessary security, and there are no ready-made design templates at all. In this case, the site is designed and created from A to Z individually for your idea.",
                        txt_ru: "Когда нужно создать уникальный или большой сайт - готовым админ панелям либо не хватает функционалу, либо нужной безопасности, а готовых дизайн-шаблонов вовсе не существует. В таком случае сайт проектируется и создаётся от А до Я индивидуально под вашу идею.",
                        calc: true,
                    },
                    {
                        work_en: "With unique design but with ready-made admin panel",
                        work_ru: "С уникальным дизайном но готовой админ панелью",
                        txt_en: "Often you need to create a website to perform popular types of tasks, such as: online stores, landing pages, portfolios, etc. At the same time, the appearance and behavior of the elements should be unique (for example, in a corporate style or as in some kind of example).",
                        txt_ru: "Часто нужно создать сайт для выполнения популярных видов задач, таких как: Интернет магазины, Лендинги, Портфолио и т.п. При этом чтобы внешний вид и поведение элементов были уникальным (например в фирменном стиле или как в каком-то референсе).",
                        calc: true,
                    },
                    {
                        work_en: "Based on a ready-made website template",
                        work_ru: "На основе готового шаблона сайтов",
                        txt_en: "When you need to quickly create a website for a popular task, such as: Online stores, Landing pages, Portfolios, etc. We can use ready-made, template solutions. You should take into account that such a solution may have slow speed, poor SEO, and may also be difficult to expand functionality.",
                        txt_ru: "Когда нужно быстро создать сайт для популярной задачи, такой как: Интернет магазины, Лендинги, Портфолио и т.п. Можно использовать готовые, шаблонные решения. Вам следует учитывать что у такого решения может быть медленная скорость, плохое SEO, а также вероятны сложности с расширением функционала.",
                        calc: true,
                    },
                    {
                        work_en: "In online website builder",
                        work_ru: "В онлайн конструкторе сайтов",
                        txt_en: "This is the fastest way to create a website. You will be able to edit and expand the functionality of the site without the participation of programmers. But this option is fraught with many disadvantages and limitations. We do not recommend it if you want promotion in search engines. Suitable for simple, informational sites with minimal functionality, such as: Business card, Landing page, Blog...",
                        txt_ru: "Это самый быстрый способ создания сайта. Вы сможете редактировать и расширять функционал сайта без участия программистов. Но этот вариант таит множество недостатков и ограничений. Не рекомендуем его если вы хотите продвижения в поисковиках. Подходит для простых, информационных сайтов с минимальным функционалом, таких как: Визитка, Лендинг, Блог...",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Integrations",
                cat_ru: "Интеграции",
                works: [
                    {
                        work_en: "Completely unique website, written from scratch",
                        work_ru: "Полностью уникальный сайт, написанный с нуля",
                        txt_en: "When you need to create a unique or large website, ready-made admin panels either lack functionality or the necessary security, and there are no ready-made design templates at all. In this case, the site is designed and created from A to Z individually for your idea.",
                        txt_ru: "Когда нужно создать уникальный или большой сайт - готовым админ панелям либо не хватает функционалу, либо нужной безопасности, а готовых дизайн-шаблонов вовсе не существует. В таком случае сайт проектируется и создаётся от А до Я индивидуально под вашу идею.",
                        calc: true,
                    },
                    {
                        work_en: "With unique design but with ready-made admin panel",
                        work_ru: "С уникальным дизайном но готовой админ панелью",
                        txt_en: "Often you need to create a website to perform popular types of tasks, such as: online stores, landing pages, portfolios, etc. At the same time, the appearance and behavior of the elements should be unique (for example, in a corporate style or as in some kind of example).",
                        txt_ru: "Часто нужно создать сайт для выполнения популярных видов задач, таких как: Интернет магазины, Лендинги, Портфолио и т.п. При этом чтобы внешний вид и поведение элементов были уникальным (например в фирменном стиле или как в каком-то референсе).",
                        calc: true,
                    },
                    {
                        work_en: "Based on a ready-made website template",
                        work_ru: "На основе готового шаблона сайтов",
                        txt_en: "When you need to quickly create a website for a popular task, such as: Online stores, Landing pages, Portfolios, etc. We can use ready-made, template solutions. You should take into account that such a solution may have slow speed, poor SEO, and may also be difficult to expand functionality.",
                        txt_ru: "Когда нужно быстро создать сайт для популярной задачи, такой как: Интернет магазины, Лендинги, Портфолио и т.п. Можно использовать готовые, шаблонные решения. Вам следует учитывать что у такого решения может быть медленная скорость, плохое SEO, а также вероятны сложности с расширением функционала.",
                        calc: true,
                    },
                    {
                        work_en: "In online website builder",
                        work_ru: "В онлайн конструкторе сайтов",
                        txt_en: "This is the fastest way to create a website. You will be able to edit and expand the functionality of the site without the participation of programmers. But this option is fraught with many disadvantages and limitations. We do not recommend it if you want promotion in search engines. Suitable for simple, informational sites with minimal functionality, such as: Business card, Landing page, Blog...",
                        txt_ru: "Это самый быстрый способ создания сайта. Вы сможете редактировать и расширять функционал сайта без участия программистов. Но этот вариант таит множество недостатков и ограничений. Не рекомендуем его если вы хотите продвижения в поисковиках. Подходит для простых, информационных сайтов с минимальным функционалом, таких как: Визитка, Лендинг, Блог...",
                        calc: true,
                    },
                ],
            },
        ],
    },
    {   id: 3,
        name_en: "Soft",
        name_ru: "Софт",
        de1_ru: "Скажи какой софт ты используешь,",
        de1_en: "Tell me what software you use,",
        de2_ru: "и я скажу чем ты занимаешься...",
        de2_en: "and I'll tell you what you do...",
        cats: [
            {
                cat_en: "Team",
                cat_ru: "Командный",
                works: [
                    {
                        work_en: "Logo / Icons / Stamp",
                        work_ru: "Логотип / Иконки / Печать",
                        txt_en: "A symbol of your business. Acts as advertising for new clients and recognition for old ones as an indicator of solving their problems. It is difficult to underestimate its importance! What should it be like? We will help you with ideas and implementation.",
                        txt_ru: "Символ вашего дела. Выполняет роль рекламы для новых клиентов и узнаваемости для старых как индикатор решения их задач. Сложно недооценить его важность! Каким он должен быть? Мы поможем вам с идеями и реализацией.",
                        calc: true,
                    },
                    {
                        work_en: "Everything for Printing",
                        work_ru: "Всё для Полиграфии",
                        txt_en: "Before going to a printing house to print a brochure, business card, banner or anything else, it is important to have decent source files and knowledge of what and how to print. If you turn to printing house employees to create materials, you run the risk of getting something primitive.",
                        txt_ru: "Прежде чем идти в типографию печать брошюры, визитки, баннера или что то ещё - важно иметь при себе достойные исходные файлы и знания что, как печатать. Обратившись за созданием материалов к сотрудникам типографии, вы рискуете получить примитив.",
                        calc: true,
                    },
                    {
                        work_en: "Corporate identity / Brand book",
                        work_ru: "Фирстиль / Брендбук",
                        txt_en: "Appearance is an important part of a company's image. Brand colors, signage, design elements, clothing, fonts, furniture and much more. A brand book is a set of rules and best practices for designers from different fields when working with the appearance of your company.",
                        txt_ru: "Внешний вид это важная часть имиджа компании. Фирменные цвета, вывески, элементы оформления, одежда, шрифты, мебель и многое другое. Брендбук - это свод правил и наработок для дизайнеров из разных сфер при работе с внешним видом вашей компании.",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Design, Graphics",
                cat_ru: "Дизайн, Графика",
                works: [
                    {
                        work_en: "Processing and Editing of photos",
                        work_ru: "Обработка и Монтаж фотографий",
                        txt_en: "These works are most often carried out at the design stages of a future project or filling the project with content - when selected photographs and pictures need to be brought into proper form before being published for public viewing.",
                        txt_ru: "Данные работы чаще всего выполняются на этапах дизайна будущего проекта или наполнения проекта контентом - когда отобранные фотографии и картинки нужно привести в подобающий вид перед публикацией на общее обозрение.",
                        calc: true,
                    },
                    {
                        work_en: "Video Editing, Overlay effects",
                        work_ru: "Видеомонтаж, Наложение эффектов",
                        txt_en: "These works are most often performed with videos shot for an advertising campaign, as well as at the stage of filling the project with content - when the filmed video needs to be finalized or simply brought into proper form before publishing it for public viewing.",
                        txt_ru: "Данные работы чаще всего выполняются с видео снятыми для рекламной кампании, а также на этапе наполнения проекта контентом - когда снятое видео нужно доработать или просто привести в подобающий вид перед публикацией на общее обозрение.",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Coding",
                cat_ru: "Кодинг",
                works: [
                    {
                        work_en: "Vector drawing",
                        work_ru: "Векторная отрисовка",
                        txt_en: "Vector illustrations differ from raster ones in their very small file size. This is very important when working with online projects because download speed should be high. But there is also a flip side to the coin - vector images are more difficult to create.",
                        txt_ru: "Векторные иллюстрации отличаются от растровых очень маленьким размером файлов. Это очень важно при работе с онлайн проектами т.к. скорость загрузки должна быть высокой. Но есть и обратная сторона медали - векторные изображения сложнее в создании.",
                        calc: true,
                    },
                    {
                        work_en: "Raster solutions",
                        work_ru: "Растровые решения",
                        txt_en: "We will create an illustration of any complexity - cartoon, photorealistic, oil style, futuristic... any! For screens or for printing. At the end of the work, you will have PNG, JPG, PDF or any other high-resolution raster format.",
                        txt_ru: "Создадим иллюстрацию любой сложности - мультяшную, фотореалистичную, в стиле маслом, футуристичную ... любую! Для экранов или для печати. В конце работы у вас будут PNG, JPG, PDF или любой другой растровый формат в высоком разрешении.",
                        calc: true,
                    },
                    {
                        work_en: "3D visualization",
                        work_ru: "3D визуализация",
                        txt_en: "3D comes to the rescue when you need to create accurate visualizations of products, drawings, objects... anything! Having created the model once, you can generate its images from different angles as much as you want. It is also possible to insert 3D into a project and clients will be able to rotate it in real time.",
                        txt_ru: "3D спешит на помощь когда вам нужно создать точную визуализацию товаров, чертежей, объектов ... чего угодно!  Создав модель единожды можно генерить её изображения с разных ракурсов, сколько захочется. Также можно вставить 3D в проект и клиенты смогут вращать её в реальном времени.",
                        calc: true,
                    },
                ],
            },
            {
                cat_en: "Server",
                cat_ru: "Сервер",
                works: [
                    {
                        work_en: "Motion design / Vector animation",
                        work_ru: "Моушн дизайн / Векторная анимация",
                        txt_en: "There are many ways to create 2D animation in the modern world. For example: animated banners, or cartoons as «Simsons», or overlaying interactive elements on top of videos. The style and detailing of objects can be different: cartoonish, strict, technical, vintage... any.",
                        txt_ru: "Существует множество способов создать 2Д анимацию в современном мире. Например анимированные баннеры или мультики аля «Симпсоны» или наложение интерактивных элементов поверх видео. Стилистика и детализация объектов может быть разной: мультяшной, строгой, технической, винтажной ... любой.",
                        calc: true,
                    },
                    {
                        work_en: "3D animation",
                        work_ru: "3D анимация",
                        txt_en: "Modern 3D animation has no limitations - any idea can be realized. And the main beauty is that it can be superimposed on a video and get realistic augmented reality. Let it be objects, space around or even characters.",
                        txt_ru: "Современная 3D анимация не имеет ограничений, воплотить можно любую идею. А главная прелесть в том что её можно наложить на видео и получить реалистичную дополненную реальность. Пусть то будет объекты, пространство вокруг или даже персонажи.",
                        calc: true,
                    },
                ],
            },
        ],
    },
]

export const pg_Services2 = [
    {  id:0,
        en: "Services range",
        ru: "Спектр услуг",
    },
    {  id:1,
        en: "Calculate cost",
        ru: "Посчитать цену",
    },
    {  id:2,
        en: "Ask a question",
        ru: "Задать вопрос",
    },
    {  id:3,
        en: "I have a question about",
        ru: "У меня есть вопрос о",
    },
]






















