import React, {useContext, useEffect} from 'react';
import {AuthContext} from '../context/Context.js';

const PaymentGood = () => {
    const {lang, setAppFirstLoad} = useContext(AuthContext)

    // loading
    useEffect(() => {
        setAppFirstLoad(false)
    }, [setAppFirstLoad])

    return (
        <h1>
            { lang === 'en' ? "Payment was successful" : "Платёж прошёл успешно" }
        </h1>
    );
};

export default PaymentGood;