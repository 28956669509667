import React, {useContext, useEffect, useRef, useState} from 'react';
import {AuthContext} from '../context/Context.js';
import {pg_Portfolio1, pg_Portfolio2} from "../context/pg_Portfolio"
import {globPrice_Portfolio} from "../context/GlobPrices"
import {Link, useParams} from "react-router-dom";

const PgWork = () => {
    // states and vars
    const params = useParams()
    const {lang, exchange, isLandscape, isPad, setMenuPage, appFirstLoad, setAppFirstLoad, toTopListener, setToTopListener,} = useContext(AuthContext)
    const [pgLoaded, setPgLoaded] = useState(false)
    const thisWork = pg_Portfolio2.find(el => el.id === params.id)

    // refs
    const h1 = useRef()
    const txtWrp = useRef()

    // functions
    function groupNumber(data) {
        let tmp = data.toLocaleString('ru-RU')
        return tmp
    }

    // loading
    useEffect(() => {
        if(pgLoaded){
            return
        }else{
            setPgLoaded(true)
            setMenuPage('portfolio')
        }

        let descStartTimeOffset = 2500
        let mobStartTimeOffset = 1900
        if(!appFirstLoad){
            descStartTimeOffset = 800
            mobStartTimeOffset = 600
        }

        // add listeners to .TOTOP
        if(!toTopListener){
            setToTopListener(true)

            // when footer
            function onEntry(entry) {
                if(document.querySelector('.totop')){
                    entry.forEach(change => {
                        if (change.isIntersecting) {
                            document.querySelector('.totop').classList.add('totop-end')
                        }else{
                            document.querySelector('.totop').classList.remove('totop-end')
                        }
                    });
                }
            }
            let observer = new IntersectionObserver(onEntry, { threshold: [0.5] });
            observer.observe(document.querySelector('.footer-com'));

            // when scroll
            document.addEventListener("scroll", function () {
                if(document.querySelector('.totop')){
                    if(window.scrollY > 200){
                        document.querySelector('.totop').style.opacity = "1"
                    }else{
                        document.querySelector('.totop').style.opacity = "0"
                    }
                }
            })
        }

        if(isLandscape){
            setTimeout(function () {
                h1.current.style.maxWidth = '100%'
                h1.current.style.borderBottom = '1px solid #333'
                h1.current.children[1].style.color = '#555'
                h1.current.children[1].style.textShadow = 'rgba(0,0,0,.3) 0 .45vw .8vw, 0 -1px rgba(254,254,254,.45)'
                setTimeout(function () {
                    txtWrp.current.style.maxHeight = '20vw'
                }, 1000)
                setTimeout(function () {
                    document.getElementsByClassName('onepic')[0].style.maxHeight = "1000vh"
                    document.getElementsByClassName('onepic')[0].style.opacity = "1"
                    if(!thisWork.glued){
                        document.getElementsByClassName('onepic')[0].style.marginBottom = "3vw"
                    }
                }, 1500)
                if(document.getElementsByClassName('onepic')[1]){
                    setTimeout(function () {
                        document.getElementsByClassName('onepic')[1].style.maxHeight = "1000vh"
                        if(!thisWork.glued){
                            document.getElementsByClassName('onepic')[1].style.marginBottom = "3vw"
                        }
                    }, 2000)
                }
                if(document.getElementsByClassName('onepic')[2]){
                    setTimeout(function () {
                        document.getElementsByClassName('onepic')[2].style.maxHeight = "1000vh"
                        if(!thisWork.glued){
                            document.getElementsByClassName('onepic')[2].style.marginBottom = "3vw"
                        }
                    }, 2500)
                }
                if(document.getElementsByClassName('onepic')[3]){
                    setTimeout(function () {
                        document.getElementsByClassName('onepic')[3].style.maxHeight = "1000vh"
                        if(!thisWork.glued){
                            document.getElementsByClassName('onepic')[3].style.marginBottom = "3vw"
                        }
                    }, 3000)
                }
                setTimeout(function () {
                    document.querySelectorAll('.page-work-images .onepic').forEach((onepic, i) => {
                        if(i > 3){
                            onepic.style.maxHeight = 'unset'
                            if(!thisWork.glued){
                                onepic.style.marginBottom = "3vw"
                            }
                        }
                    })
                }, 3500)
                setTimeout(function () {
                    document.querySelector('.page-work-footer-back').style.opacity = "1"
                    h1.current.style.overflow = "unset"
                    setTimeout(function () {
                        h1.current.children[0].style.opacity = "1"
                    }, 100)
                }, 4500)
            },descStartTimeOffset)
        }else{
            let w1 = ''
            if(isPad){
                w1 = ''
            }

            setTimeout(function () {
                h1.current.children[1].style.opacity = '1'
                h1.current.children[0].style.opacity = "1"
                setTimeout(function () {
                    txtWrp.current.style.maxHeight = '100vw'
                    txtWrp.current.style.opacity = '1'
                }, 300)
                setTimeout(function () {
                    document.getElementsByClassName('onepic')[0].style.maxHeight = "500vh"
                    document.getElementsByClassName('onepic')[0].style.opacity = "1"
                    if(!thisWork.glued){
                        document.getElementsByClassName('onepic')[0].style.marginBottom = "5vw"
                    }
                }, 600)
                if(document.getElementsByClassName('onepic')[1]){
                    setTimeout(function () {
                        document.getElementsByClassName('onepic')[1].style.maxHeight = "500vh"
                        if(!thisWork.glued){
                            document.getElementsByClassName('onepic')[1].style.marginBottom = "5vw"
                        }
                    }, 1000)
                }
                if(document.getElementsByClassName('onepic')[2]){
                    setTimeout(function () {
                        document.getElementsByClassName('onepic')[2].style.maxHeight = "500vh"
                        if(!thisWork.glued){
                            document.getElementsByClassName('onepic')[2].style.marginBottom = "5vw"
                        }
                    }, 1500)
                }
                if(document.getElementsByClassName('onepic')[3]){
                    setTimeout(function () {
                        document.getElementsByClassName('onepic')[3].style.maxHeight = "500vh"
                        if(!thisWork.glued){
                            document.getElementsByClassName('onepic')[3].style.marginBottom = "5vw"
                        }
                    }, 2000)
                }
                setTimeout(function () {
                    document.querySelectorAll('.page-work-images .onepic').forEach((onepic, i) => {
                        if(i > 3){
                            onepic.style.maxHeight = 'unset'
                            if(!thisWork.glued){
                                onepic.style.marginBottom = "5vw"
                            }
                        }
                    })
                }, 2500)
                setTimeout(function () {
                    document.querySelector('.page-work-footer-back').style.opacity = "1"
                }, 3500)
            },mobStartTimeOffset)
        }

        setAppFirstLoad(false)
    }, [isLandscape, isPad, appFirstLoad, setAppFirstLoad, pgLoaded, setPgLoaded, toTopListener, setToTopListener, setMenuPage])

    return (
        <div className='page page-work'>
            <div className="page-work-tit" ref={h1}>
                <Link className="page-work-tit-back" to={`/portfolio${lang !== 'en' ? '/'+lang : ''}`}>«</Link>
                <h1>{ thisWork[`name_${lang}`]
                    ? thisWork[`name_${lang}`]
                    : thisWork[`cat_${lang}`]
                }</h1>
            </div>
            <div className="page-work-txt" ref={txtWrp}>
                <p>{thisWork[`description_${lang}`]}</p>
                { thisWork.buy ?
                    <div>
                        <p>{pg_Portfolio1[5][`${lang}`]}: <span>{lang === 'en' ? '$' : '₽'}</span> {
                            lang === 'en'
                                ? groupNumber(Math.round(globPrice_Portfolio * thisWork.hours / 80)) +
                                    ' ' + pg_Portfolio1[10][`${lang}`] + ' - ' + pg_Portfolio1[11][`${lang}`] + '.' +
                                    `${thisWork.limit_en ? pg_Portfolio1[8][`${lang}`] + ' ' + thisWork[`limit_${lang}`] + '.' : ''}`
                                : groupNumber(Math.round(globPrice_Portfolio * thisWork.hours * exchange / 80000) * 1000) +
                                    ' ' + pg_Portfolio1[10][`${lang}`] + ' - ' + pg_Portfolio1[11][`${lang}`] + '.' +
                                    `${thisWork.limit_ru ? pg_Portfolio1[8][`${lang}`] + ' ' + thisWork[`limit_${lang}`] + '.' : ''}`
                        }</p>
                        <p>{pg_Portfolio1[6][`${lang}`]}: <span>{lang === 'en' ? '$' : '₽'}</span> {
                            lang === 'en'
                                ? groupNumber(Math.round(globPrice_Portfolio * thisWork.hours / 4)) +
                                    ' - ' + pg_Portfolio1[11][`${lang}`] + '.' +
                                    `${thisWork.limit_en ? pg_Portfolio1[8][`${lang}`] + ' ' + thisWork[`limit_${lang}`] + '.' : ''}`
                                : groupNumber(Math.round(globPrice_Portfolio * thisWork.hours * exchange / 4000) * 1000) +
                                    ' - ' + pg_Portfolio1[11][`${lang}`] + '.' +
                                    `${thisWork.limit_ru ? pg_Portfolio1[8][`${lang}`] + ' ' + thisWork[`limit_${lang}`] + '.' : ''}`
                        }</p>
                    </div>
                : ''}
                <p>{pg_Portfolio1[7][`${lang}`]}:{ thisWork[`name_${lang}`]
                    ? ''
                    : <span> ~ {pg_Portfolio1[3][`${lang}`]}</span>
                } <span>{lang === 'en' ? '$' : '₽'}</span> { lang === 'en'
                    ? groupNumber(Math.round(globPrice_Portfolio * thisWork.hours))
                    : groupNumber(Math.round(globPrice_Portfolio * thisWork.hours * exchange / 1000) * 1000)
                }.</p>
                { thisWork.link ? <a href={thisWork.url} target="_blank" rel="noreferrer">{thisWork.link}</a> : '' }
            </div>
            <div className={`page-work-images ${thisWork.glued ? 'glued' : ''}`}>
                { thisWork.pics_mob && !isLandscape
                    ? thisWork.pics_mob.map((pic, i) => {
                        if(pic === 'hr'){
                            return(
                                <hr className="onepic" key={i + "_pic"}/>
                            )
                        }else{
                            if(pic[pic.length -1] === '4'){
                                return(
                                    <video className="onepic" key={i + "_pic"} autoPlay loop muted>
                                        <source src={`/img/portfolio/${thisWork.folder}/${pic}`} type="video/mp4" />
                                    </video>
                                )
                            }else{
                                return(
                                    <img className="onepic" key={i + "_pic"} src={`/img/portfolio/${thisWork.folder}/${pic}`} alt={pic}/>
                                )
                            }
                        }
                    })
                    : thisWork.pics.map((pic, i) => {
                        if(pic === 'hr'){
                            return(
                                <hr className="onepic" key={i + "_pic"}/>
                            )
                        }else{
                            if(pic[pic.length -1] === '4'){
                                return(
                                    <video className="onepic" key={i + "_pic"} autoPlay loop muted>
                                        <source src={`/img/portfolio/${thisWork.folder}/${pic}`} type="video/mp4" />
                                    </video>
                                )
                            }else{
                                return(
                                    <img className="onepic" key={i + "_pic"} src={`/img/portfolio/${thisWork.folder}/${pic}`} alt={pic}/>
                                )
                            }
                        }
                    })
                }
            </div>
            <div className="page-work-footer">
                <Link className="page-work-footer-back" to={`/portfolio${lang !== 'en' ? '/'+lang : ''}`}>
                    <span>«</span> {pg_Portfolio1[9][`${lang}`]}
                </Link>
                <div className="totop" onClick={() => {window.scrollTo(0, 0)}}>▲</div>
                <div className="page-work-footer-empty"> </div>
            </div>
        </div>
    );
};

export default PgWork;