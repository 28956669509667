export const pg_MainText = [
    {   id: 0,
        en: "5 reasons to choose me:",
        ru: "5 причин за Романа:",
    },
    {   id: 1,
        en: "The range of skills allows me to assign almost any type of development task.",
        ru: "Спектр навыков позволяет ставить мне почти любые виды задач по разработке.",
    },
    {   id: 2,
        en: "Was a part of different projects. I can easily adapt to the mechanics of any company.",
        ru: "Побыв частью разных проектов, легко адаптируюсь в механику любой компании.",
    },
    {   id: 3,
        en: "Only commercial experience in the IT industry for more than 14 years, but that’s not all!",
        ru: "Только коммерческий стаж в IT индустрии более 14 лет, а ведь это не всё :)",
    },
    {   id: 4,
        en: "I work with employee auto-calculating & screen monitoring software without problems.",
        ru: "Без проблем работаю с софтом авто-учёта и мониторинга экрана сотрудника.",
    },
    {   id: 5,
        en: "I'm ready to work in a startup at a reduced rate if we agree on a partnership.",
        ru: "В стартапе готов работать по меньшей ставке, если договоримся о партнёрстве.",
    },
    {   id: 6,
        en: "What i can do",
        ru: "Что я умею",
    },
    {   id: 7,
        en: "Portfolio",
        ru: "Портфолио",
    },
    {   id: 8,
        en: "Work story",
        ru: "История работы",
    },
    {   id: 9,
        en: "Wishes for work",
        ru: "Пожелания к работе",
    },
    {   id: 10,
        en: "Partnership terms",
        ru: "Условия партнёрства",
    },
]


































